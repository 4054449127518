<template>
  <div class="view-model">
    
    <div id="container" class="conta">
      <!--进度条-->
      <div id="progress" class="progress">
        <div class="mask"></div>
        <div class="loading">
          <div class="trends"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Loading } from "element-ui";

export default {
  name: "stldemo",
  data() {
    return {
      loadingInstance: "",
      camera: "",
      scene: "",
      renderer: "",
      mesh: "",
    };
  },
  mounted() {
    this.getType();
    // window.onresize = () => {
    //     this.onWindowResize();
    // };
  },
  methods: {
     toLink(val){           
                this.$router.push(val);
            },
    getType() {
      this.loadingInstance = Loading.service({
        lock: true,
        text: "loading",
        spinner: "el-icon-loading",
      });
      this.getScene();
      this.initControls();
      this.initStlModel();
    },
    getScene() {
      let container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        130,
        document.getElementById("container").clientWidth /
          document.getElementById("container").clientHeight,
        1,
        4000
      );
      this.camera.position.z = 700;

      this.camera.position.set(0, 200, 300);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.scene = new THREE.Scene();

      var pointLight = new THREE.PointLight(0xcccccc);
      pointLight.position.set(0, 2, 0);
      pointLight.castShadow = true;

      this.camera.add(pointLight);
      this.scene.add(this.camera);

      // 显示坐标系
      // var axes = new THREE.AxesHelper(100);
      // this.scene.add(axes);

      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      this.renderer.setClearAlpha(0);
      //设置分辨率
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);
    },

    //加载stl文件
    initStlModel() {
      // 加载过程回调函数-可以获得加载进度
      function onProgress(xhr) {
        if (xhr.lengthComputable) {
          console.log(xhr);
          let percentComplete = (xhr.loaded / xhr.total) * 100;
          console.log(Math.round(percentComplete, 2) + "% downloaded");
          play(Math.round(percentComplete, 2));
        }
      }

      // 这是一个进度条函数进度条函数
      function play(a) {
        document.getElementsByClassName("trends")[0].style.width = a + "%";
        if (a < 100) {
          document.getElementById("progress").style.display = "block";
        } else {
          document.getElementById("progress").style.display = "none";
        }
      }

      let mtlLoader = new MTLLoader();
      mtlLoader.load("female02.mtl", (materials) => {
        materials.preload();
        let Loader = new OBJLoader();
        Loader.setMaterials(materials);
        Loader.load(
          "https://oneboxes.oss-cn-shenzhen.aliyuncs.com/zd1cccc4ynl7hlr5fxwn.obj",
          (object) => {
            this.loadingInstance.close();
            let fontGeometry = object.children[0].geometry;
            fontGeometry.computeBoundingBox();
            let box = new THREE.Box3().setFromObject(object); // 获取模型的包围盒
            let mdlen = box.max.x - box.min.x; // 模型长度
            let mdwid = box.max.z - box.min.z; // 模型宽度
            let mdhei = box.max.y - box.min.y; // 模型高度
            let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
            let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
            let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z

            // object.position.set(-x1, -y1, -z1); // 将模型进行偏移
            fontGeometry.center();

            object.position.x = -x1;
            object.position.y = -y1;
            object.position.z = -z1;
            this.scene.add(object);
            console.log(333);
            this.render();
          },
          onProgress
        );
      });
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    initControls() {
      var controls = new OrbitControls(this.camera, this.renderer.domElement);

      //解决选中时的边框问题
      this.renderer.domElement.removeAttribute("tabindex");
      // 如果使用animate方法时，将此函数删除
      controls.addEventListener("change", this.render);
      // 使动画循环使用时阻尼或自转 意思是否有惯性
      controls.enableDamping = true;
      //动态阻尼系数 就是鼠标拖拽旋转灵敏度
      //controls.dampingFactor = 0.25;
      //是否可以缩放
      controls.enableZoom = true;
      //是否自动旋转
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.5;
      //设置相机距离原点的最远距离
      controls.minDistance = 1;
      //设置相机距离原点的最远距离
      // controls.maxDistance = 200;
      //是否开启右键拖拽
      controls.enablePan = false;
    },

    // onWindowResize() {
    //     this.camera.aspect = window.innerWidth / window.innerHeight;
    //     this.camera.updateProjectionMatrix();
    //     this.render();
    //     this.renderer.setSize( window.innerWidth, window.innerHeight );
    // }
  },
};
</script>

<style scoped>
.view-model {
  height: 100%;
  width: 100%;
  position: relative;
  /* background: #a0a2a6; */
}
.conta {
  height: 100%;
  width: 100%;
}
/* 进度条 */
.progress {
  position: fixed;
  width: 100%;
  height: 100%;
}
.progress .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.progress .loading {
  width: 30em;
  height: 1em;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 1em;
}
.progress .trends {
  width: 0;
  height: 100%;
  background: rgb(134, 130, 130);
  box-shadow: 1px 1px 10px rgb(100, 98, 98);
  border-radius: 1em;
}
</style>